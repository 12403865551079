/* Styles globaux */
body, html {
  margin: 0;
  padding: 0; /* Suppression du padding */
  height: 100%;
  font-family: Arial, sans-serif;
  overflow: hidden; /* Emp�che le d�filement global */
}

.dashboard-container {
  display: flex;
  height: 100vh; /* Assure que le conteneur prend toute la hauteur de la fen�tre */
  overflow: hidden; /* Emp�che le d�filement global */
}

/* Bloc 1 */
.bloc1 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  position: sticky; /* Fixe le bloc1 en position */
  top: 0; /* Fixe le bloc1 en haut de la page */
  height: 100vh; /* Assure que le bloc1 prend toute la hauteur de la fen�tre */
  overflow: hidden; /* Emp�che le d�filement � l'int�rieur de bloc1 */
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 60%; /* Taille r�duite du logo */
  height: auto;
}

/* Menu 1 */
.menu1 {
  list-style-type: none;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 20px; /* Ajustement de la marge pour �viter le chevauchement */
}

.menu1 li {
  padding: 10px 5px; /* Diminution de la taille du padding */
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative; /* Important pour le positionnement du sous-menu */
  font-size: 14px; /* Diminution de la taille de police */
}

.menu1 li .MuiSvgIcon-root {
  margin-right: 10px;
}

.menu1 li:hover,
.menu1 li.active {
  background-color: #E4F3F9;
  font-weight: bold;
  border-radius: 5px;
}

/* Sous-menu pour Analytics */
.submenu {
  display: none;
  position: absolute;
  top: 100%; /* Positionn� sous le menu principal */
  left: 0;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 180px;
  z-index: 1000;
}

.submenu li {
  padding: 10px;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submenu li:hover {
  background-color: #E4F3F9;
}

.menu1 li:hover .submenu {
  display: block; /* Affiche le sous-menu lors du survol */
}

/* Bouton 1 et Bouton CLE */
.bouton1, .boutoncle {
  background-color: #000;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  width: 35%; /* Ajustement de la taille du bouton */
}

.bouton1:hover, .boutoncle:hover {
  background-color: #0699C1;
}

/* Bloc 2 */
.bloc2 {
  flex-grow: 1; /* Prend l'espace restant � droite de bloc1 */
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
  overflow-y: auto; /* Activer le d�filement vertical */
  height: 100vh; /* Assure que le bloc2 prend toute la hauteur de la fen�tre */
}

/* Bloch1 pour le nom de l'administrateur et les ic�nes */
.bloch1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 20px;
  background-color: #E1F4FA;
  padding: 5px;
  position: relative;
}

.admin-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.admin-icon {
  position: relative;
}

.admin-icon img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.admin-menu {
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 150px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.admin-icon:hover .admin-menu,
.admin-icon:focus .admin-menu {
  display: block;
  opacity: 1;
}

.admin-menu a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
}

.admin-menu a:hover {
  background-color: #f0f0f0;
}

/* Ic�ne de notifications */
.notifications-icon img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -5px;
}

/* Bloch2 pour le texte de bienvenue */
.bloch2 {
  margin-bottom: 20px;
}

.welcome-text {
  font-size: 24px;
  color: #333;
  padding-left: 10px;
}

/* Bloch3 pour le contenu principal */
.bloch3 {
  flex-grow: 1;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  overflow-x: hidden; /* Emp�che le d�filement horizontal */
}

/* Bouton CLE sp�cifique */
.boutoncle {
  display: inline-block;
  align-items: center;
  width: 35%; /* Ajustement de la taille du bouton � 35% */
  padding: 10px; /* Ajustement du padding pour respecter l'espace int�rieur */
}

.boutoncle:hover {
  background-color: #0699C1;
}

/* Styles pour bloch3home, bloch3le et bloch3pl */
#bloch3home, #bloch3le, #bloch3pl {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bloch3ple, .bloch3ppl, .bloch3ue, .bloch3oe, .bloch3prl, .bloch3ueprivate, .bloch3oeprivate {
  background-color: #e1f4fa;
  color: black;
  width: 100%;
  height: 17%;
}

.bloch3total {
  background-color: #75B8FF;
  color: white;
  height: 30%;
}

.titretn, .titreue, .titreoe, .titreueprivate, .titreoeprivate {
  color: blue;
  text-align: left;
  font-size: 18px;
}

/* Styles pour bloch3ple */
.bloch3ple {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: stretch; /* Assure que les enfants prennent toute la hauteur disponible */
}

/* Styles pour le nouveau bloc "Pending LIVE EVENTS" */
.pending-live-events {
  width: 30%; /* Ajustez cette valeur selon vos besoins */
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre le contenu horizontalement */
  justify-content: center; /* Centre le contenu verticalement */
  text-align: center; /* Centre le texte */
  margin: 0 auto; /* Centre le bloc horizontalement */
}

.pending-live-events h4 {
  margin-top: 0;
}

.pending-live-events p {
  margin-bottom: 0;
}

/* Styles pour le carrousel horizontal */
.carousel-container {
  overflow-x: auto; /* Activer le d�filement horizontal */
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre le contenu verticalement */
  position: relative;
}

.carousel {
  display: inline-flex;
  gap: 10px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
}

.carousel-button img {
  width: 20px;
  height: 20px;
}

.carousel-button.left {
  left: -30px;
}

.carousel-button.right {
  right: -30px;
}

.live-event {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.live-event:hover, .pending-live-events:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.event-date, .event-time, .vendor-name, .product-count {
  margin: 5px 0;
}

.vendor-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.vendor-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  gap: 10px;
}

.vendor-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.vendor-name {
  font-size: 0.9em;
  text-align: center;
}

/* Styles pour le bloc "Upcoming Events" */
.bloch3ue, .bloch3oe {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden; /* Emp�che le d�filement horizontal */
  max-height: 400px; /* Ajustez cette valeur selon vos besoins */
  background-color: #fff; /* Couleur de fond blanche */
  border: 1px solid #ccc; /* Bordure grise */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Effet 3D l�ger */
  margin-bottom: 20px; /* Espacement ext�rieur */
}

.bloch3ueh {
  display: flex;
  flex-direction: row;
  width: 100%; /* Largeur de 100% du conteneur parent */
  margin-bottom: 20px; /* S�paration blanche horizontale */
  background-color: #fff; /* Couleur de fond blanche */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Effet 3D */
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 10px; /* Coins arrondis */
  text-decoration: none;
  color: inherit;
}

.bloch3ueh:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}

.bloch3ueh > div {
  display: flex;
  flex-direction: column; /* Assure que les enfants sont empil�s verticalement */
  align-items: center;
  justify-content: center;
  padding: 1px;
  width: 100%;
}

.bloch3ueh .vertical-divider {
  width: 1px;
  background: linear-gradient(to bottom, #0000ff, #00ffff);
  margin: 8px;
  height: 80px;
}

.titreue {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.titreoe {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.titretn, .titreueprivate, .titreoeprivate {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Styles pour le popupnle */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupnle {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
}

.popup-title {
  color: blue;
  font-size: 20px;
  margin-bottom: 10px;
}

.formnle label {
  display: block;
  margin-top: 10px;
}

.formnle input,
.formnle select {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-delete, .popup-publish, .popup-close {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.popup-delete:hover, .popup-publish:hover, .popup-close:hover {
  background-color: #0699C1;
}

/* Ajout d'une fl�che retour dans le popup */
.popupnle button {
  background: none;
  border: none;
  cursor: pointer;
  color: blue;
  font-size: 18px;
}

/* Styles pour la liste des notifications */
.notification-list {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.notification-item.unread {
  background-color: #f0f8ff;
}

.notification-item.read {
  background-color: #fff;
}

.notification-date {
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
}

.show-more-button {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.show-more-button:hover {
  background-color: #0699C1;
}

/* Styles pour le compte � rebours */
.bloch3ueh2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.bloch3ueh2 div {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.countdown {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown span {
  margin: 0 5px;
}

/* Styles pour le carrousel des vendeurs participants */
.bloch3ueh3 .carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bloch3ueh3 .carousel {
  display: flex;
  scroll-behavior: smooth;
  gap: 10px;
}

.bloch3ueh3 .carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
}

.bloch3ueh3 .carousel-button.left {
  left: -30px;
}

.bloch3ueh3 .carousel-button.right {
  right: -30px;
}

.bloch3ueh3 .vendor-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.bloch3ueh3 .vendor-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 5px;
  object-fit: cover;
}

.bloch3ueh3 .vendor-name {
  font-size: 0.9em;
  text-align: center;
}

/* Styles pour les �v�nements */
.bloch3ueh1, .bloch3ueh2, .bloch3ueh3 {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.bloch3ueh1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bloch3ueh2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bloch3ueh3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.event-date {
  font-weight: bold;
  margin-bottom: 5px;
}

.event-date-settings {
  font-size: 2em; /* Double la taille de la date */
  margin-top: 20px; /* Ajoute un espace entre bloch1 et bloch2 */
  color: rgb(127, 210, 243); /* Couleur de la date */
  font-weight: bold; /* Met le texte en gras */
}

.event-time, .product-count {
  margin-bottom: 5px;
}

.bloch3hmenu {
  background-color: black;
  height: 40px;
  width: 100%;
  margin: 0;
}

.main-settings-title {
  color: #7fd2f3;
  margin-top: 10px;
}

.bloch3-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.bloch3ledetail, .bloch3lestore, .bloch3leshare {
  background-color: #d2e7f7;
  width: 30%;
  height: 200px; /* Adjust the height as needed */
  padding: 10px;
  border-radius: 5px;
}

.bloch3hstatus {
  display: none; /* Masqu� par d�faut */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  padding: 30px 0;
}

.bloch3hstatus.visible {
  display: flex; /* Affiche le bloc */
  opacity: 1;
}

.bloch3hstatus1,
.bloch3hstatus2,
.bloch3hstatus3 {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent background */
  padding: 20px;
  text-align: center;
}

.bloch3ledetail .event-date {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.bloch3ledetail .product-count {
  font-size: 14px;
  margin-bottom: 10px;
}

.bloch3ledetail .event-starts-in {
  font-size: 14px;
  margin-bottom: 10px;
}

.store-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.waiting-stores {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Styles sp�cifiques pour les carrousels */
.carousel-container.valid-carousel {
  height: 100px; /* Ajustez cette valeur selon vos besoins */
}

.carousel-container.pending-or-refused-carousel {
  height: 100px; /* Ajustez cette valeur selon vos besoins */
}

.carousel {
  display: flex;
  scroll-snap-type: x mandatory;
}

.vendor-details {
  flex: 0 0 auto;
  scroll-snap-align: start;
  margin: 0 10px;
}

.vendor-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.vendor-name {
  text-align: center;
  margin-top: 5px;
}

.bloch3lestore .store-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.bloch3lestore .confirmed-stores-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.bloch3hstatus .vertical-divider {
  width: 1px;
  background: linear-gradient(to bottom, #0000ff, #00ffff);
  margin: 8px;
  height: 150px;
}

.bloch3hstatus1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent background */
  padding: 20px;
  text-align: center;
}

.bloch3hstatus1 .vendor-name {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.bloch3hstatus1 .vendor-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #98FB98; /* Vert pistache */
  border-radius: 50%;
  margin-left: 5px;
}