/* src/VendorDashboard.css */

/* Styles globaux */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

/* Bloc 1 */
.bloc1 {
  width: 15%;
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100vh;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 50%;  /* R�duction suppl�mentaire de la taille du logo */
  height: auto;
}

/* Menu 1 */
.menu1 {
  list-style-type: none;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 20px; /* R�duction de l'espacement en bas */
}

.menu1 li {
  padding: 10px 5px; /* Diminution de la taille du padding */
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px; /* R�duction de la taille de police */
}

.menu1 li .MuiSvgIcon-root {
  margin-right: 5px;
}

.menu1 li:hover,
.menu1 li.active {
  background-color: #e1ecf2;
  font-weight: bold;
  border-radius: 5px;
}

/* Bouton 1 */
.bouton1 {
  background-color: #000;
  color: #fff;
  padding: 10px;
  margin-top: 10px; /* Moins d'espace en haut */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  width: 80%;
  position: sticky; /* Rend le bouton visible m�me avec du scroll */
  bottom: 20px;
}

.bouton1:hover {
  background-color: #5d5fea;
}

/* Bloc 2 */
.bloc2 {
  width: 85%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
  overflow-y: auto; /* D�filement vertical activ� */
}

/* Bloch1 pour le nom de l'utilisateur et les ic�nes */
.bloch1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 20px;
  background-color: #e1f4fa;
  padding: 5px;
  position: relative;
  z-index: 1; /* Assurez-vous que ce bloc est au-dessus des autres �l�ments */
}

.admin-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.admin-icon img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.admin-menu {
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 150px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.admin-icon:hover .admin-menu {
  display: block;
  opacity: 1;
}

.admin-menu a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
}

.admin-menu a:hover {
  background-color: #f0f0f0;
}

/* Ic�ne de notifications */
.notifications-icon {
  position: relative;
  cursor: pointer;
  z-index: 2; /* Assurez-vous que l'ic�ne de notification est au-dessus des autres �l�ments */
}

.notifications-icon img {
  width: 24px;
  height: 24px;
}

.notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
}

/* Liste des notifications */
.notification-list {
  position: absolute;
  top: 50px;
  right: 20px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
}

.notification-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-item.unread {
  background-color: #bdd7df; /* Couleur de fond pour les notifications non lues */
}

.notification-item.read {
  background-color: #f9f9f9; /* Couleur de fond pour les notifications lues */
}

.notification-item:hover {
  background-color: #ddd;
}

.notification-date {
  font-size: 12px;
  color: #888;
  margin-left: 10px;
}

/* Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-content button {
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #ddd;
}

/* Bloch3home */
#bloch3home {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cadre-abonnement {
  width: 25%;
  height: 30px;
  background-color: #fff;
  color: #0000FF;
  display: flex;
  border-style: groove;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.upgrade-link {
  color: #0000FF;
}

.block-content {
  margin-top: 10px; /* Espace pour le titre */
  flex-grow: 1;
}

.join-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center; /* Centre le bouton */
  margin-top: 10px;
}

.join-button:hover {
  background-color: #333333; /* Changement de couleur au survol */
}

/* Conteneur principal pour l'alignement */
.flex-container {
  display: flex;
  justify-content: space-between; /* Espace entre les �l�ments */
  align-items: flex-start; /* Alignement en haut des �l�ments */
  gap: 20px; /* Espace entre les blocs */
}

/* Blocs align�s pour "Live Events" et "Private Lives" */
.cadrele, .cadreule, .cadrepl, .cadreupl {
  width: 30%; /* Largeur de chaque bloc principal */
  background-color: #0000FF; /* Exemple de couleur */
  color: white;
  padding: 10px;
  border-radius: 8px;
}

/* Ajustement pour les blocs "Renew" */
.cadrenew { 
  width: 30%;
  background-color: #E1F4FA;
  border-radius: 8px;
  padding: 10px;
  position: relative; /* Permet de garder la position sans affecter les autres */
  height: auto; /* Permet � la hauteur de s'ajuster sans affecter les autres */
}

/* Media query pour �crans plus petits */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column; /* Les blocs s'empilent sur petits �crans */
  }

  .cadrenew, .cadrele, .cadreule, .cadrepl, .cadreupl {
    width: 100%; /* Prend toute la largeur */
  }
}

/* Bloc "Total" align� en bas de tous les autres */
.cadretotal {
  padding: 5px;
  background-color: #E4F3F9;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
}