/* src/Popup.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 24px; /* Set the button size to match the icon size */
  height: 24px; /* Set the button size to match the icon size */
}

.close-button img {
  width: 24px; /* Set the icon size */
  height: 24px; /* Set the icon size */
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px; /* Space between buttons */
}

.next-button, .reject-button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px; /* Padding for buttons */
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Font size for buttons */
}

.reject-button {
  background: #ff5c5c;
}

.next-button:hover, .reject-button:hover {
  background-color: #0056b3; /* Darker blue for hover effect */
}

.reject-button:hover {
  background-color: #e60000; /* Darker red for hover effect */
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 0;
}

.product-item {
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s, transform 0.3s;
  position: relative;
  width: calc(50% - 20px);
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.product-item p {
  text-align: center;
  margin: 10px 0 0;
  font-size: 14px;
}

.product-item.selected {
  border-color: #007bff;
  transform: scale(1.05);
}

.product-item .ok-sign {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
}

.accept-icon {
  display: block;
  margin: 20px auto 0;
  width: 67px;
  height: 67px;
}